import React from "react";
import "../assets/TearmsAndCondition.css";

function PrivacyPolicy() {
  return (
    <div className="tearmsandc">
      <h1 className="headingTop">Privacy Policy </h1>

      <p className="teramspera">
        Right to privacy is a Fundamental right implicit in Article 21 (Right to
        Life and Liberty) of Indian Constitution and at the same time, Privacy
        protection in Digital Age has become most challenging and prime area of
        concern. Now who doesn’t love their Privacy? We at, Geographical
        Indications Tagged World Premium Products (p) Ltd, have made Protection
        of Customer’s Information as a top priority of the Organization.
      </p>
      <p className="teramspera">Collection limitation:</p>
      <p className="teramspera">
        Data or Information that is required for the said purpose shall only be
        asked by us and our customers shall provide only such Information that
        they feel necessary for our long term fruitful endeavor;
      </p>
      <p className="teramspera">Purpose limitation:</p>
      <p className="teramspera">
        • Shall be relevant to the purpose of Processing and shipping your
        Orders.
      </p>
      <p className="teramspera">
        • Shall be adequate enough to ease future operations, to share Website
        related news, promote offers, and perform website usage analytics.
      </p>
      <p className="teramspera">
        • In case of any change of purpose, the same shall be notified to the
        Concerned Customer or customers through a general notification in the
        website.
      </p>

      <p className="teramspera">Access limitation:</p>
      <p className="teramspera">
        Information access shall be limited to only selected staff of the
        Organization.
      </p>
      <p className="teramspera">
        • Such staff shall secure information by reasonable security safeguards
        against loss, unauthorized access, unauthorized disclosure, either
        accidental or incidental or any other reasonably foreseeable risks.
      </p>
      <p className="teramspera">
        • Such staff shall be accountable for complying with measures of this
        Policy.
      </p>

      <p className="teramspera">Disclosure of Information:</p>
      <p className="teramspera">
        • Carrier Service Network providers, OTP Provider Softwares, User
        Experience enrichment softwares, Logistics and Payment gateway for
        Processing and shipping your Orders.
      </p>
      <p className="teramspera">
        • State, Central Government and its Agencies under relevant Acts.
      </p>
      <p className="teramspera">• Court orders or other legal processes.</p>
      <p className="teramspera">
        • Third parties who are relevant and shall bound to adhere to this
        policy.
      </p>

      <p className="teramspera">
        By using this website, the customer implies to agree to the terms of
        this privacy policy and has permitted the organization to collect,
        store, and use, Personally Identifiable Information (PIF), such as name,
        address, phone number, email id, bank account number, Aadhaar number and
        credit card information and system-related data such as Cookies and IP
        address within the preview of this Privacy policy.
      </p>

      <p className="teramspera">
        If any difficulty arises in giving effect to the provisions of this
        policy, the Organization may make such provisions, as appears to it to
        be necessary or expedient for removing such difficulty by providing a
        general notification in the website.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
