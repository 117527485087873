import React from "react";
import "../assets/TearmsAndCondition.css";

function RefundPolicy() {
  return (
    <div>
      <div className="tearmsandc">
        <h1 className="headingTop">Refund/Cancellation Policy </h1>

        <p className="teramspera">
          Thanks for being a patron with StarLudo (referred as "StarLudo"). If
          you are not entirely satisfied with your subscription, we are here to
          help.
        </p>

        <p className="teramspera">Refund</p>

        <p className="teramspera">
          Once we receive your Refund request, we will inspect it and notify you
          on the status of your refund.
        </p>

        <p className="teramspera">
          If your refund request is approved, we will initiate a refund to your
          credit card (or original method of payment) within 7 working days. You
          will receive the credit within a certain amount of days, depending on
          your card issuer's policies.
        </p>

        <p className="teramspera">
          In case of unforeseen technical glitch, StarLudo would refund
          subscription upon reviewing the complaint. Final decision lies with
          the company.
        </p>
      </div>
    </div>
  );
}

export default RefundPolicy;
